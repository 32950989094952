<template lang="pug">
  .matching-table
    table
      thead
        th.inventory-group-name.sortable(
          @click="handleSorting('name')"
        )
          |{{ $t("inventory_group_car_class_matching.inventory_group_name") }}
          FaIcon.icon(:icon="sortingIcon('name')")
        th.car-classes {{ $t("inventory_group_car_class_matching.car_class") }}
        th.selected-count
      tbody
        tr(
          v-for="inventoryGroup in inventoryGroups"
          :key="inventoryGroup.id"
        )
          td.inventory-group-name {{ inventoryGroup.name }}
          td.car-classes
            AppDropdown(
              checkbox
              z-index="103"
              batch-select
              multiple
              allow-empty
              searchable
              close-on-select
              draggable-selected
              :placeholder="$t('inventory_group_car_class_matching.select_car_class')"
              :value="inventoryGroup.selected_car_classes"
              :items="inventoryGroup.available_car_classes"
              @select="selectCarClass(inventoryGroup, $event)"
            )
          td.selected-count(:class="{ updated: inventoryGroup._updated }")
            | {{ carClassesCountsByInventoryGroup[inventoryGroup.id].selected }}
            | /
            | {{ carClassesCountsByInventoryGroup[inventoryGroup.id].available }}
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  // misc
  import { reduce } from "lodash-es"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [withSorting],

    props: {
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      sortingData: {
        type: Object,
        default: () => ({ field: "name", direction: "asc" })
      }
    },

    computed: {
      carClassesCountsByInventoryGroup({ inventoryGroups }) {
        return reduce(
          inventoryGroups,
          (obj, { id, selected_car_classes, available_car_classes }) => {
            obj[id] = { selected: selected_car_classes.length, available: available_car_classes.length }

            return obj
          },
          {}
        )
      }
    },

    methods: {
      selectCarClass(inventoryGroup, carClasses) {
        this.$emit("change", { ...inventoryGroup, selected_car_classes: carClasses })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/matchings.sass"

  .matching-table
    +listing-container(-43px)
    +matchings-table-header
    width: 100%

    table
      width: 100%

      .inventory-group-name,
      .car-classes
        width: calc((100% - 100px) / 2)

      .selected-count
        text-align: center
        width: 100px

        &.updated
          color: $default-green
          font-weight: 700

      tbody
        td
          +custom-table-td
</style>
